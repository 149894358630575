interface Breakpoints {
  isDesktop: Ref<boolean>;
  isLaptop: Ref<boolean>;
}

const useBrowserBreakpoints = (): Breakpoints => {
  const breakpoints = useBreakpoints({
    desktop: 769,
    laptop: 1300,
  });

  const isDesktop = breakpoints.greaterOrEqual("desktop");
  const isLaptop = breakpoints.greaterOrEqual("laptop");

  return {
    isDesktop,
    isLaptop,
  };
};

export { useBrowserBreakpoints };
