const useAppStore = () => {
  const isMenuCabinetVisible = useState("menu-cabinet-visible", () => false);
  const isMenuVisible = useState("menu-visible", () => false);
  const { isDesktop } = useBrowserBreakpoints();

  const toggleMenu = (state: boolean) => {
    isMenuVisible.value = state;
  };

  const toggleMenuCabinet = (state: boolean) => {
    isMenuCabinetVisible.value = state;
  };

  watchPostEffect(() => {
    isMenuCabinetVisible.value = isDesktop.value;

    if (isMenuVisible.value) {
      isMenuVisible.value = !isDesktop.value;
    }
  });

  return {
    isMenuCabinetVisible,
    isMenuVisible,
    toggleMenu,
    toggleMenuCabinet,
  };
};

export { useAppStore };
